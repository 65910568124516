import React from 'react';
import { SolutionInfoType } from './infoContent';

const wallCavity = (infoType: SolutionInfoType) => (
  <div>
    {infoType === 'general' && (
      <p>
        Spouwmuurisolatie (ookwel gevelisolatie) is het isoleren van de spouw: de ruimte tussen de
        binnen- en buitenmuur van je gevel. Deze ruimte is bedoeld om vochtproblemen te verminderen
        en kan gelukkig goed geïsoleerd worden. Daarmee zorgt de spouwmuur dat de kou buiten blijft
        en niet de spouw in kan stromen. Dat scheelt zo'n 30% tot 35% op je stookkosten.
      </p>
    )}
    {infoType === 'help' && (
      <div>
        <div className="h5 bold">Hoe weet ik of mijn woning spouwmuren heeft?</div>
        <ul className="no-top-margin">
          <li>
            Als de woning <strong>voor 1920</strong> gebouwd is, is er hoogstwaarschijnlijk{' '}
            <strong>geen</strong> spouw
          </li>
          <li>
            Als de volledige muur dikker is dan 24cm, dan is er hoogstwaarschijnlijk een spouw
            aanwezig
          </li>
          <li>
            Als er verticale ventilatievoegen (vaak 3 of 4 verticale bakstenen) zijn, dan is er
            zeker weten een spouw aanwezig
          </li>
          <li>
            Als er plastic of metalen ventilatieroosters zijn, is er wel een spouw, maar is deze
            waarschijnlijk al geïsoleerd
          </li>
          <li>
            Om het 100% zeker te weten wordt er altijd een endoscopisch onderzoek uitgevoerd voor er
            een installatie wordt gepland of uitgevoerd.
          </li>
        </ul>
      </div>
    )}
  </div>
);

export default wallCavity;
