import React from 'react';
import { SolutionInfoType } from './infoContent';

const pvSystem = (infoType: SolutionInfoType) => (
  <div>
    {infoType === 'general' && (
      <>
        <p>
          Eigenlijk bedoelen we met zonnepanelen een zonnestroomsysteem (ookwel{' '}
          <strong>PV-systeem</strong>, naar het Engelse{' '}
          <span className="italic">photovoltaics</span>). Zo'n systeem bestaat naast de panelen uit
          een omvormer, een montagesysteem en eventuele optimizers en batterijen. Deze onderdelen
          komen in verschillende uitvoeringen en kunnen onderling erg verschillen in
          (bouw-)kwaliteit en prestaties. Het is daarom belangrijk betrouwbare partij te kiezen die
          met goede producten en installateurs werkt.
        </p>
        <p>
          Bij Energiebespaarders krijg je voor de berekende investering niet alleen de
          daadwerkelijke panelen, maar ook de omvormer, het montagesysteem en de installatie van
          gegarandeerd hoge kwaliteit. Wij werken alleen met A-merk producten en gecertificeerde
          installateurs.
        </p>
      </>
    )}
  </div>
);

export default pvSystem;
