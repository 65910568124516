import { constants, getYearlyFinancialSavings, Solution } from '@energiebespaarders/constants';
import { EnergyPrices } from './../hooks/usePersonalEnergyPrices';
import { Estimate } from './../types/generated/Estimate';
const { emissionFactors } = constants;

// NOTE: This function returns an object that takes into account the gasFactors of the separate estimates or quotes

/** NOTE: This is similar to EnergyDelta: the values are the _change_ in consumption: they are the energy savings */
export interface RelativeSavingTotals {
  gasConsumption: number;
  electricityConsumption: number;
  electricityProduction: number;
  money: number;
  emission: number;
  energyIndex: number;
}

type IEstimateInput = Pick<Estimate, 'energyDelta' | 'solution'>;

/** Also accepts estimates */
export function calculateRelativeTotals(
  quotes: IEstimateInput[],
  consumption: { gas: number; electricity: number },
  energyPrices = constants.energyPrices as EnergyPrices,
): RelativeSavingTotals {
  let totalEnergyIndex = 0;
  let newGasConsumption = consumption.gas;
  let totalElectricityProduction = 0;
  let totalElectricityConsumption = 0;

  quotes.forEach(quote => {
    totalEnergyIndex += quote.energyDelta.energyIndex;
    newGasConsumption -= newGasConsumption * quote.energyDelta.gasFactor;
    totalElectricityProduction += quote.energyDelta.electricityProduction;
    totalElectricityConsumption += quote.energyDelta.electricityConsumption;
  });

  const isHeatPumpQuote = (q: IEstimateInput) =>
    ([Solution.HybridHeatPump, Solution.ElectricHeatPump] as Solution[]).includes(q.solution);
  const hasHeatPump = quotes.some(quote => isHeatPumpQuote(quote));

  let insulationGasFactor = 1;

  if (hasHeatPump) {
    // Can only enable one type of heat pump at a time for calcs
    const heatPumpQuote = quotes.find(quote => isHeatPumpQuote(quote))!;
    quotes.forEach(quote => {
      if (!isHeatPumpQuote(quote)) {
        insulationGasFactor = insulationGasFactor * (1 - quote.energyDelta.gasFactor);
      }
    });

    totalElectricityConsumption =
      totalElectricityConsumption -
      heatPumpQuote.energyDelta.electricityConsumption -
      200 +
      (heatPumpQuote.energyDelta.electricityConsumption + 200) * insulationGasFactor;
  }

  const totalGasSavings = consumption.gas - newGasConsumption;
  const totalElectricitySavings = totalElectricityProduction + totalElectricityConsumption;

  const money = getYearlyFinancialSavings(
    consumption,
    {
      gasConsumption: totalGasSavings,
      electricityConsumption: totalElectricityConsumption,
      electricityProduction: totalElectricityProduction,
    },
    energyPrices,
  ).total;

  return {
    money,
    gasConsumption: totalGasSavings,
    electricityConsumption: totalElectricityConsumption,
    electricityProduction: totalElectricityProduction,
    emission:
      totalGasSavings * emissionFactors.gas + totalElectricitySavings * emissionFactors.electricity,
    energyIndex: totalEnergyIndex,
  };
}
