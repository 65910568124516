import { Box, Flex, Icon, Image, TextLink } from '@energiebespaarders/symbols';
import { Book, Color, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import { Information, Lightbulb } from '@energiebespaarders/symbols/icons/solid';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import styled from 'styled-components';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import { boxShadow } from '../../styles/mixins';
import { AddressInput } from '../../types/graphql-global-types';
import BCQuestionInput from './BCQuestionInput';
import { SavedAnswers } from './SavingsCheckModule';
import { BCAnswerValue, BCQuestion, IConsumption, QuestionKey } from './getCards';

const StyledBCCard = styled.div<{ $status: 'current' | 'next' | 'done' }>`
  ${boxShadow('sm')};
  background: white;
  margin: 0 auto;
  position: absolute;
  right: 15px;
  left: 15px;
  display: inline-block;
  max-width: ${x => x.theme.containers.md};
  width: calc(100% - 30px);
  border-radius: 6px;
  transform: translate(100vw);
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;

  ${mediaMin.md} {
    right: 0;
    left: 0;
    animation-duration: 0.75s;
  }

  ${mediaMin.lg} {
    width: ${x => x.theme.containers.md};
  }
`;

const StyledNumber = styled.div`
  text-align: right;
  color: ${x => x.theme.colors.gray};
  font-size: ${x => x.theme.type.scale[7]};
`;

const StyledPrevButton = styled.div`
  color: ${x => x.theme.colors.gray};
  font-size: ${x => x.theme.type.scale[7]};
  cursor: pointer;

  &:hover {
    color: ${x => x.theme.colors.grayDark};
  }

  &:active {
    color: ${x => x.theme.colors.grayDarker};
  }
`;

const StyledMoreInfo = styled.div`
  text-align: right;
`;

const ShowOnHover = styled.span`
  display: inline-block;
  opacity: 0;
  max-width: 0;
  white-space: nowrap;
  vertical-align: middle;
  overflow: hidden;
  transition: all 0.1s;
`;

const StyledTextLink = styled(TextLink)`
  &:hover {
    ${ShowOnHover} {
      opacity: 1;
      max-width: 100%;
    }
  }
`;

export interface BCCardProps {
  amountCards: number;
  answered: boolean;
  answers: SavedAnswers;
  awaitMutation: boolean;
  card: BCQuestion;
  cardIndex: number;
  containerHeight: number;
  emailRequired: boolean;
  emailValue: string;
  handleStartBC: (values: AddressInput) => void;
  initializing: boolean;
  isEbvCheck: boolean;
  navigateCards: (dir: 'previous' | 'next') => void;
  onFinish: () => void;
  saveAnswerInState: (key: QuestionKey, value: BCAnswerValue | IConsumption) => void;
  setContainerHeight: (height: number) => void;
  setEmail: Dispatch<SetStateAction<string>>;
  startCount: number;
  status: 'current' | 'next' | 'done';
  toggleInfoModal: (key: QuestionKey) => void;
}

export const BCCard: React.FC<BCCardProps> = ({
  amountCards,
  card,
  cardIndex,
  containerHeight,
  navigateCards,
  status,
  toggleInfoModal,
  setContainerHeight,
  ...passThroughProps
}) => {
  const { activeHouseId } = useActiveHouseId();
  const [animation, setAnimation] = useState(status === 'current' ? 'slide-in--right' : '');

  const prevStatusRef = useRef(status);
  const prevStatus = prevStatusRef.current;

  const cardRef = useRef<HTMLDivElement | null>(null);
  const handleUpdateContainerHeight = useCallback(() => {
    const currentCardHeight = cardRef.current?.offsetHeight;
    if (currentCardHeight && currentCardHeight !== containerHeight && status === 'current') {
      setContainerHeight(currentCardHeight);
    }
  }, [containerHeight, setContainerHeight, status]);
  const debouncedUpdateHeight = useDebounce(handleUpdateContainerHeight, 100); // FIXME: debouncedUpdateHeight is an array, not a height value

  useEffect(() => {
    window.setTimeout(() => debouncedUpdateHeight, 500);
    window.addEventListener('resize', () => debouncedUpdateHeight);
    return () => window.removeEventListener('resize', () => debouncedUpdateHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newAnimationClass = '';
    switch (true) {
      // Return to previous card
      case prevStatus === 'done' && status === 'current':
        newAnimationClass = 'slide-in--left';
        break;
      case prevStatus === 'current' && status === 'next':
        newAnimationClass = 'slide-out--right';
        break;
      // Go to next card
      case status === 'current':
        newAnimationClass = 'slide-in--right';
        break;
      case prevStatus === 'current' && status === 'done':
        newAnimationClass = 'slide-out--left';
        break;
      // Remove animation class
      case prevStatus === 'done' && status === 'done':
      default:
        newAnimationClass = '';
        break;
    }
    setAnimation(newAnimationClass);
    prevStatusRef.current = status;
    handleUpdateContainerHeight();
  }, [status]); // eslint-disable-line

  useEffect(() => handleUpdateContainerHeight(), [activeHouseId, handleUpdateContainerHeight]);

  const handlePrevious = useCallback(() => navigateCards('previous'), [navigateCards]);
  return (
    <StyledBCCard
      data-id="bc-card"
      className={animation}
      $status={status}
      aria-hidden={status !== 'current'}
      ref={cardRef}
    >
      <Flex flexWrap="wrap" style={{ position: 'relative' }} px={[2, 4]} py={4}>
        <Box width={1 / 2} mt={-2}>
          {cardIndex > 1 && <StyledPrevButton onClick={handlePrevious}>← Vorige</StyledPrevButton>}
        </Box>
        <Box width={1 / 2} mt={-2}>
          {activeHouseId && (
            <StyledNumber>
              {cardIndex} / {amountCards}
            </StyledNumber>
          )}
        </Box>
        <Box width={1}>
          <Flex
            flexWrap={['wrap', 'nowrap']}
            mx={[0, -2]}
            alignItems="center"
            justifyContent="center"
          >
            {card.image && (
              <Box width={['90px', '150px', '210px']} px={[0, 2]}>
                <Image src={card.image} alt={card.question || ''} radius="999px" />
              </Box>
            )}
            <Box width={1} p={2} textAlign="center">
              {card.question && (
                <>
                  <p>
                    <Book>{card.question}</Book>
                  </p>

                  {card.explainer && (
                    <Smaller>
                      <p>
                        <Icon icon={Lightbulb} fill="orange" mr={1} />
                        <Color c="grayDark">{card.explainer}</Color>
                      </p>
                    </Smaller>
                  )}
                </>
              )}

              <BCQuestionInput
                {...passThroughProps}
                card={card}
                onAnswer={() => navigateCards('next')}
                status={status}
              />
            </Box>
          </Flex>
        </Box>
        {card.hasExtraInfo && (
          <Box width={1} mb={-2}>
            <StyledMoreInfo>
              <Small>
                <p>
                  <StyledTextLink
                    color="blue"
                    hoverColor="blueDark"
                    onClick={() => toggleInfoModal(card.key)}
                  >
                    <ShowOnHover>Meer informatie</ShowOnHover>
                    <Icon icon={Information} fill="blue" hoverColor="blueDark" ml={1} />
                  </StyledTextLink>
                </p>
              </Small>
            </StyledMoreInfo>
          </Box>
        )}
      </Flex>
    </StyledBCCard>
  );
};

export default BCCard;
