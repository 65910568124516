import React from 'react';
import { SolutionInfoType } from './infoContent';

const floorInsulation = (infoType: SolutionInfoType, constructionYear: number) =>
  infoType === 'general' ? (
    <p>
      Vloerisolatie is eigenlijk een vorm van kruipruimteisolatie. Vloerisolatie is namelijk het
      isoleren van de onderkant van je vloer, of het plafond van de kruipruimte. Bodemisolatie, een
      tweede vorm van kruipruimteisolatie, is het isoleren van de bodem van de kruipruimte. Beiden
      zorgen voor een grote verhoging van het wooncomfort en voor significante energiebesparing.
      Kelders kunnen op een soortgelijke manier geïsoleerd worden, maar zijn minder geschikt omdat
      deze vaak ook voor andere doeleinden gebruikt worden.
    </p>
  ) : (
    <>
      {constructionYear >= 1983 ? (
        <>
          <div className="h5 bold">Wat houdt na-isoleren precies in?</div>
          <p>
            Na-isolatie is het extra isoleren na de bouw. Woningen gebouwd na 1983 zijn bij de bouw
            voorzien van vloerisolatie. Echter zijn veel van de materialen die toen zijn gebruikt
            ondertussen grotendeels vervallen en toe aan vernieuwing. Bovendien zijn de moderne
            isolatiematerialen milieuvriendelijker, duurzamer en hebben ze hogere isolatiewaarden.
            Na-isolatie levert meestal geen significant rendement op, maar verbetert wel het
            wooncomfort en voorkomt nadelige gevolgen van oudere isolatiematerialen.
          </p>
          <div className="h5 bold">Hoe weet ik of mijn vloer nageïsoleerd is?</div>
          <ul className="no-top-margin">
            <li>
              In een bouwkundig rapport of taxatierapport staat meestal of de vloer nageïsoleerd is
            </li>
            <li>
              In de aan- of verkoopbrochure van de woning staat meestal of de vloer nageïsoleerd is
            </li>
          </ul>
        </>
      ) : (
        <>
          <div className="h5 bold">Hoe weet ik of mijn vloer al geïsoleerd is?</div>
          <ul className="no-top-margin">
            <li>
              Kijk in de kruipruimte of kelder of er isolatiemateriaal aanwezig is (dit kan dus ook
              tegen de onderkant van je vloer zitten)
            </li>
            <li>
              In de verkoopbrochure, het bouwkundig rapport of het taxatierapport van de woning
              staat meestal of de vloer geïsoleerd is
            </li>
          </ul>
        </>
      )}
    </>
  );

export default floorInsulation;
