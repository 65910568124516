import { Container } from '@energiebespaarders/symbols';
import { Heading } from '@energiebespaarders/symbols/helpers';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const AdviserIntakeStartedRedirect = () => {
  const [timeLeft, setTimeLeft] = useState(7);
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => setTimeLeft(prev => prev - 1), 1000);
    if (timeLeft === 0) {
      void router.replace('/mijn-verduurzaming');
    }
  }, [router, timeLeft]);

  return (
    <Container textAlign="center" pt={5}>
      <Head>
        <title key="title">U wordt doorgestuurd...</title>
        <meta name="robots" key="robots" content="noindex" />
      </Head>
      <Heading heading="h3">Er is al een woningopname gestart</Heading>
      <div className="deb-divider" />
      <p>
        Het is daarom niet mogelijk om de Bespaarcheck voor deze woning (opnieuw) te doen. Bij de
        woningopname wordt namelijk de gehele woning in kaart gebracht. In het advies en de offertes
        worden alle berekeningen per geadviseerde oplossing getoond.
        <br />U wordt over{' '}
        <strong>
          {timeLeft} seconde{timeLeft === 1 ? '' : 'n'}
        </strong>{' '}
        automatisch doorgestuurd.
      </p>
    </Container>
  );
};

export default AdviserIntakeStartedRedirect;
