import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { HOUSE_TYPES_NL, SOLUTION_DOMAINS_NL, SolutionDomain } from '@energiebespaarders/constants';
import { Container, Progress, Spinner } from '@energiebespaarders/symbols';
import { Center } from '@energiebespaarders/symbols/helpers';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ALREADY_HAS_ADDRESS_ERROR } from '~/components/AddressFields/utils';
import { getLocalJourneyData } from '~/components/RequestPackage/utils';
import BCCard from '~/components/SavingsCheck/BCCard';
import filterCards from '~/components/SavingsCheck/filterCards';
import { BCAnswerValue, QuestionKey, questionDomainDict } from '~/components/SavingsCheck/getCards';
import SolutionInfoModal from '~/components/SolutionInfo';
import usePathname from '~/hooks/usePathname';
import AdviserIntakeStartedRedirect from '../../envCustomer/AdviserIntakeStartedRedirect';
import { ExtendedQuestionKey } from '../../envPartner/PartnerCheckExtended/extendedCards';
import useABTest, { GET_AB_TEST_VARIANT } from '../../hooks/useABTest';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import useGAEvent from '../../hooks/useGAEvent';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import { useLeadEmail } from '../../hooks/useLeadEmail';
import { useMeOptional } from '../../hooks/useMe';
import { INITIALIZE_USER_HOUSE } from '../../queries/house';
import {
  finishInitialQuestions,
  finishInitialQuestionsVariables,
} from '../../types/generated/finishInitialQuestions';
import { getABTestEntry, getABTestEntryVariables } from '../../types/generated/getABTestEntry';
import {
  initialQuestions,
  initialQuestionsVariables,
} from '../../types/generated/initialQuestions';
import {
  initializeUserHouse,
  initializeUserHouseVariables,
} from '../../types/generated/initializeUserHouse';
import { AddressInput } from '../../types/graphql-global-types';
import { inIFrame } from '../../utils/iFrameUtils';
import LoadError from '../LoadError';
import { FINISH_INITIAL_QUESTIONS } from './mutations';

export const INITIAL_QUESTIONS = gql`
  query initialQuestions($id: ID!) {
    house(id: $id) {
      id
      type
      constructionYear
      area
      vve
      residents
      pvSystemPower
      crawlspace {
        compartments
      }
      walls {
        cavity
        rc
      }
      floor {
        rc
      }
      roof {
        rc
      }
      windowsZTAU {
        livingU
        sleepingU
      }
      heating {
        boilerAge
      }
      advice {
        id
        isRequested
      }
      intake {
        id
        isStarted
      }
    }
  }
`;

const StyledBCModule = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transition: height 0.15s;
`;

interface SavingsCheckProps {
  redirect?: string;
}

export type SavedAnswers = {
  [x in QuestionKey | ExtendedQuestionKey]?: BCAnswerValue;
};

export const SavingsCheckModule: React.FC<SavingsCheckProps> = ({
  redirect = '/bespaarmogelijkheden',
}) => {
  const { me } = useMeOptional();
  const { leadEmail } = useLeadEmail();
  const { abTestEntries, setAbTestEntries } = useABTest();
  const { activeHouseId, setActiveHouseId } = useActiveHouseId();
  const isAuthenticated = useIsAuthenticated();
  const router = useRouter();
  const pathname = usePathname();
  const isEbvCheck = useMemo(() => pathname === '/bespaarcheck-ebv', [pathname]);
  const sendGAEvent = useGAEvent();

  const [currentCard, setCurrentCard] = useState(1);
  const [isNavigating, setIsNavigating] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [currentQuestionKey, setCurrentQuestionKey] = useState<QuestionKey | ''>('');
  const [currentSolutionDomain, setCurrentSolutionDomain] = useState<SolutionDomain | ''>('');
  const [answers, setAnswers] = useState<SavedAnswers>({});
  const [answered, setAnswered] = useState<QuestionKey[]>([]);
  const [skipEmail, setSkipEmail] = useState(false);
  const [email, setEmail] = useState(leadEmail);

  const [existingHouseId, setExistingHouseId] = useState('');
  const [newHouseId, setNewHouseId] = useState('');
  const [startCount, setStartCount] = useState(0);

  const [initializeUserHouseMutation, { loading: initializingHouse }] = useMutation<
    initializeUserHouse,
    initializeUserHouseVariables
  >(INITIALIZE_USER_HOUSE, {
    onCompleted: data =>
      sendGAEvent('collect_home_info', {
        house_id: data.initializeUserHouse.id,
        construction_year: data.initializeUserHouse.constructionYear,
        house_type: HOUSE_TYPES_NL[data.initializeUserHouse.type],
        zip: data.initializeUserHouse.address.zip,
      }),
  });
  const [finishInitialQuestionsMutation] = useMutation<
    finishInitialQuestions,
    finishInitialQuestionsVariables
  >(FINISH_INITIAL_QUESTIONS);

  const [getIntialQuestionsData, { data: houseData, loading: houseLoading, error }] = useLazyQuery<
    initialQuestions,
    initialQuestionsVariables
  >(INITIAL_QUESTIONS);

  const [createAbTestEntry, { loading: abLoading, error: abError }] = useLazyQuery<
    getABTestEntry,
    getABTestEntryVariables
  >(GET_AB_TEST_VARIANT, {
    onCompleted: data =>
      data.getABTestEntry ? setAbTestEntries([...abTestEntries, data.getABTestEntry]) : null,
    onError: e => console.error(e),
  });

  useEffect(() => {
    if (me?.id && !abLoading && !abError && activeHouseId) {
      if (!abTestEntries.some(entry => entry.domain === 'resultsPage')) {
        void createAbTestEntry({ variables: { userId: me.id, domain: 'resultsPage' } });
      }
      if (!abTestEntries.some(entry => entry.domain === 'bespaarcheck')) {
        void createAbTestEntry({ variables: { userId: me.id, domain: 'bespaarcheck' } });
      }
    }
  }, [abError, abLoading, createAbTestEntry, activeHouseId, me?.id, abTestEntries]);

  useEffect(() => {
    if (!houseData && activeHouseId) {
      void getIntialQuestionsData({ variables: { id: activeHouseId } });
    }
  }, [houseData, getIntialQuestionsData, activeHouseId]);

  // Handle old vs new houses and setting the right one active
  useEffect(() => {
    if (newHouseId && newHouseId !== activeHouseId) {
      // Starting a new BC for a new house (clean slate)
      setExistingHouseId('');
      setActiveHouseId(newHouseId);
      void sendGAEvent('quick_scan_begin', { house_id: newHouseId });
      setStartCount(v => v + 1);
      return;
    } else if (existingHouseId && existingHouseId !== activeHouseId) {
      // Starting a BC for an existing house that isn't active
      setNewHouseId('');
      setActiveHouseId(existingHouseId);
      setStartCount(v => v + 1);
      return;
    } else if (activeHouseId && activeHouseId === existingHouseId) {
      // Restarting a BC for the current active house
      setExistingHouseId('');
      setStartCount(v => v + 1);
      return;
    }
  }, [activeHouseId, existingHouseId, newHouseId, sendGAEvent, setActiveHouseId]);

  const redirectUrl = useMemo(
    () => `${redirect || (isEbvCheck ? '/bespaarmogelijkheden-ebv' : '/bespaarmogelijkheden')}`,
    [isEbvCheck, redirect],
  );

  const handleKeydown = useCallback(
    async (e: KeyboardEvent) => {
      const journeyData = getLocalJourneyData();

      // block 'Tab' button, except for first card (CardAddress)
      if (currentCard > 1 && (e.keyCode === 0 || e.keyCode === 9)) {
        e.preventDefault();
        e.stopPropagation();
      }

      // press shift+ctrl+alt+] to skip email requirement (dev purposes)
      if (e.ctrlKey && e.altKey && e.shiftKey && e.keyCode === 221) {
        setSkipEmail(!skipEmail);
      }

      // press shift+ctrl+alt+\ to skip BC (dev purposes)
      if (e.ctrlKey && e.altKey && e.shiftKey && e.keyCode === 220) {
        await finishInitialQuestionsMutation({
          variables: {
            houseId: activeHouseId,
            email: email || '',
            journeyData,
          },
        });
        void router.push(redirectUrl);
      }
    },
    [
      activeHouseId,
      currentCard,
      email,
      finishInitialQuestionsMutation,
      router,
      skipEmail,
      redirectUrl,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown]);

  const navigateCards = useCallback(
    (direction: 'previous' | 'next') => {
      if (isNavigating) return;
      setIsNavigating(true);
      if (direction === 'previous') {
        setCurrentCard(currentCard - 1);
        setTimeout(() => {
          setIsNavigating(false);
        }, 100);
      } else if (direction === 'next') {
        setCurrentCard(currentCard + 1);
        setTimeout(() => {
          setIsNavigating(false);
        }, 100);
      }
    },
    [currentCard, isNavigating],
  );

  const calculateStatus = useCallback((counter: number, current: number) => {
    let status: 'current' | 'next' | 'done' = 'current';
    if (counter < current) {
      status = 'next';
    } else if (counter > current) {
      status = 'done';
    }
    return status;
  }, []);

  const toggleInfoModal = useCallback((questionKey?: QuestionKey) => {
    if (!questionKey) {
      setShowModalInfo(false);
      return;
    }
    setCurrentQuestionKey(questionKey);
    setShowModalInfo(true);

    if (questionDomainDict[questionKey]) {
      setCurrentSolutionDomain(questionDomainDict[questionKey]!);
    } else {
      setCurrentSolutionDomain('');
    }
  }, []);

  const onFinish = useCallback(async () => {
    await sendGAEvent('quick_scan_complete', {
      house_id: activeHouseId,
      medium: isEbvCheck ? 'partner' : 'funnel',
    });
    setCurrentCard(currentCard + 1);
    void router.push(redirectUrl);
    window?.localStorage.setItem('resultsEmail', email); // can be restored for advice request form
    window?.localStorage.setItem(`resultsSent-${activeHouseId}-${email}`, 'true');
  }, [sendGAEvent, activeHouseId, isEbvCheck, currentCard, router, redirectUrl, email]);

  const house = houseData?.house || null;

  const handleStartBC = async (address: AddressInput) => {
    if (!initializingHouse && me) {
      try {
        const journeyData = getLocalJourneyData();
        const initHouseRes = await initializeUserHouseMutation({
          variables: {
            userId: me.id,
            userType: me.__typename.toLowerCase(),
            address,
            journeyData,
          },
        });
        const house = initHouseRes.data?.initializeUserHouse;
        if (house) setNewHouseId(house.id);
      } catch (e) {
        // If user already has a house with this address, use that one instead
        if ((e as Error)?.message?.includes(ALREADY_HAS_ADDRESS_ERROR)) {
          // existing house id is the last word in the error message
          // TODO: Not very elegant
          // but a better option would be to use a separate response or query
          const _existingHouseId = (e as Error).message.split(' ').pop() || '';
          if (_existingHouseId) setExistingHouseId(_existingHouseId);
        } else {
          console.error(e);
          throw e;
        }
      }
    }
  };

  const [containerHeight, setContainerHeight] = useState(0);
  const resizeContainerHeight = useCallback((height: number) => setContainerHeight(height), []);

  const saveAnswerInState = useCallback(
    (key: QuestionKey, value: BCAnswerValue) => {
      setAnswers({ ...answers, [key]: value });
      setAnswered([...answered, key]);
    },
    [answered, answers],
  );

  if (activeHouseId && houseLoading)
    return (
      <Center block>
        <Spinner />
      </Center>
    );
  if (error) return <LoadError error={error} />;

  const cards = filterCards(house, answers, isEbvCheck, false, inIFrame(), abTestEntries, false);
  const amountCards = cards.length;
  return isAuthenticated.asCustomer && house?.advice.isRequested && house.intake.isStarted ? (
    <AdviserIntakeStartedRedirect />
  ) : (
    <>
      <StyledBCModule style={{ height: containerHeight + 60 }}>
        <Container size="md" mb={2} px={4}>
          <Progress
            currentProgress={currentCard / amountCards}
            showLabel={false}
            progressColor="green"
          />
        </Container>
        {cards.map((card, index) => (
          <BCCard
            key={`card-${index}`}
            amountCards={amountCards}
            answered={answered.includes(card.key)}
            answers={answers}
            card={card}
            cardIndex={index + 1}
            emailRequired={skipEmail === false && !isEbvCheck}
            emailValue={email}
            handleStartBC={(values: AddressInput) => handleStartBC(values)}
            initializing={initializingHouse}
            isEbvCheck={isEbvCheck}
            navigateCards={navigateCards}
            onFinish={onFinish}
            saveAnswerInState={saveAnswerInState}
            setEmail={setEmail}
            startCount={startCount}
            status={calculateStatus(currentCard, index + 1)}
            toggleInfoModal={toggleInfoModal}
            // NOTE: await the mutation so that the consumption card can fetch correct consumption values
            awaitMutation={card?.key === 'slurpers'}
            containerHeight={containerHeight}
            setContainerHeight={resizeContainerHeight}
          />
        ))}
      </StyledBCModule>
      <SolutionInfoModal
        closeModal={() => toggleInfoModal()}
        constructionYear={house?.constructionYear ?? 0}
        isOpen={showModalInfo}
        showHelp
        solutionNL={currentSolutionDomain ? SOLUTION_DOMAINS_NL[currentSolutionDomain] : ''}
        questionKey={currentQuestionKey}
      />
    </>
  );
};

export default SavingsCheckModule;
