import { ApolloError } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Box, Button, Checkbox, Flex, Input, RadioGroup } from '@energiebespaarders/symbols';
import { Smaller } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import { useMe } from '../../hooks/useMe';
import { me_me_Customer, me_me_Lead } from '../../types/generated/me';
import { setABTestEntryMetadataVariables } from '../../types/generated/setABTestEntryMetadata';
import { updateMotivationVariables } from '../../types/generated/updateMotivation';
import { mortgageLandingPages } from '../../utils/constants';
import AddressQuestion from './AddressQuestion';
import { BCCardProps } from './BCCard';
import { ConsumptionQuestion } from './ConsumptionQuestion';
import FinalQuestion from './FinalQuestion';
import { BCAnswer, BCAnswerValue } from './getCards';

type BCQuestionProps = Omit<
  BCCardProps,
  | 'amountCards'
  | 'cardIndex'
  | 'navigateCards'
  | 'containerHeight'
  | 'setContainerHeight'
  | 'status'
  | 'toggleInfoModal'
> & {
  onAnswer: () => void;
  status: 'current' | 'next' | 'done';
};

export const BCQuestionInput: React.FC<BCQuestionProps> = ({
  answered,
  answers,
  awaitMutation,
  card,
  emailRequired,
  emailValue,
  handleStartBC,
  initializing,
  isEbvCheck,
  onAnswer,
  onFinish,
  saveAnswerInState,
  setEmail,
  startCount,
  status,
}) => {
  const mobile = useIsMobile();
  const { activeHouseId } = useActiveHouseId();
  const { me } = useMe<me_me_Lead | me_me_Customer>();
  const [checked, setChecked] = useState<BCAnswerValue[]>([]);
  const [rangeValue, setRangeValue] = useState(0);
  const [clicked, setClicked] = useState(false);

  const tickCheckBox = (targetValue: BCAnswerValue) => {
    const value = checked;
    if (targetValue === null) {
      return null;
    }
    const newValue = [...value];
    if (newValue.includes(targetValue)) {
      const newArray = newValue.filter(x => x !== targetValue);
      return setChecked(newArray);
    }
    newValue.push(targetValue);
    return setChecked(newValue);
  };
  const fromGVA = useMemo(() => me?.utm?.source === 'gavoora', [me]);
  const askForEmail = useMemo(
    () => me?.__typename === 'Lead' && !mortgageLandingPages.includes(me.landingPage) && !fromGVA,
    [me, fromGVA],
  );

  useEffect(() => {
    if (clicked) {
      setTimeout(() => setClicked(false), 250);
    }
  }, [clicked]);

  const mutationVariables = useCallback(
    (answer?: BCAnswer) => {
      switch (card.type) {
        case 'radio':
          if (answer) {
            switch (card.key) {
              case 'greenEnergyContract':
                return {
                  userId: me?.id,
                  domain: 'resultsPage',
                  key: 'greenEnergyContract',
                  value: answer.value ? 'true' : 'false',
                } as setABTestEntryMetadataVariables;
              case 'referrer':
                return {
                  userId: me?.id,
                  domain: 'resultsPage',
                  key: 'referrer',
                  value: answer.value,
                } as setABTestEntryMetadataVariables;
              case 'motivation':
                return {
                  userId: me?.id,
                  userType: me?.__typename.toLowerCase(),
                  motivation: answer.value,
                } as updateMotivationVariables;
              default:
                return {
                  houseId: activeHouseId,
                  [card.mutationVariable || card.key]: answer.value,
                };
            }
          }
          return {};
        case 'checkbox':
          return {
            houseId: activeHouseId,
            [card.mutationVariable || card.key]: checked,
          };
        case 'slider':
          return {
            houseId: activeHouseId,
            [card.mutationVariable || card.key]: rangeValue,
          };
      }
    },
    [activeHouseId, card, checked, me, rangeValue],
  );

  const handleAnswer = async (answerValue: BCAnswerValue, mutation: any) => {
    if (!clicked) {
      saveAnswerInState(card.key, answerValue);
      if (awaitMutation) {
        await mutation();
      } else {
        mutation();
      }
      onAnswer();
    }
    setClicked(true);
  };

  // Render
  if (card.type === 'address') {
    return (
      <AddressQuestion
        handleStartBC={handleStartBC}
        startQuestions={onAnswer}
        initializing={initializing}
        startCount={startCount}
        isEbvCheck={isEbvCheck}
      />
    );
  }

  if (card.type === 'radio' && card.answerOptions?.length && card.mutation) {
    const isMotivationQuestion = card.key === 'motivation';
    return (
      <>
        <br />
        <Flex flexWrap="wrap" justifyContent="center" mx="-3px">
          {card.answerOptions!.map((answer, index) => (
            <Mutation
              key={`answer-${index}`}
              mutation={card.mutation!}
              variables={mutationVariables(answer)}
              onError={(err: ApolloError) => console.error(err)}
            >
              {(mutation: any) => {
                // Map continuous numeric value to discrete answer option
                const optionValue = card.valueMapper
                  ? card.valueMapper(answers[card.key])
                  : answers[card.key];

                return (
                  <Box
                    {...(isMotivationQuestion
                      ? { width: 1 / 2, px: '3px' }
                      : { px: ['3px', '3px', 0] })}
                  >
                    <Button
                      bgColor={
                        answered && answers && optionValue === answer.value ? 'orange' : 'green'
                      }
                      fluid={mobile || isMotivationQuestion}
                      onClick={() => handleAnswer(answer.value, mutation)}
                      px={3}
                    >
                      {answer.label}
                    </Button>
                  </Box>
                );
              }}
            </Mutation>
          ))}
        </Flex>
      </>
    );
  }
  if (card.type === 'slider' && card.mutation) {
    return (
      <>
        <br />
        <Input
          type="range"
          value={rangeValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setRangeValue(parseInt(e.target.value))}
          step={card.rangeOptions?.step}
          min={card.rangeOptions?.min}
          max={card.rangeOptions?.max}
          style={{ border: 'none' }}
          mb={0}
        />
        {card.rangeOptions && (
          <Smaller>
            <Flex justifyContent="space-between" mt={-1} mb={1}>
              <Box width={1 / 2} textAlign="left">
                {card.rangeOptions.min}
              </Box>
              <Box width={1 / 2} textAlign="right">
                {card.rangeOptions.max}
              </Box>
            </Flex>
          </Smaller>
        )}
        <Mutation
          mutation={card.mutation}
          variables={mutationVariables()}
          onError={(err: ApolloError) => console.error(err)}
        >
          {(mutation: any) => (
            <Button fluid onClick={() => handleAnswer(rangeValue, mutation)}>
              Ik heb{' '}
              {rangeValue === 0
                ? 'nog geen zonnepanelen'
                : `al ${rangeValue === 30 ? `${rangeValue}+` : rangeValue} ${
                    rangeValue === 1 ? 'paneel' : 'panelen'
                  } op mijn dak`}
            </Button>
          )}
        </Mutation>
      </>
    );
  }
  if (card.type === 'checkbox' && card.answerOptions && card.mutation) {
    return (
      <>
        <br />
        <RadioGroup
          name={`${card.key}-answers`}
          divider={2}
          onChange={(value: string) => tickCheckBox(value)}
        >
          {card.answerOptions.map(option => (
            <Checkbox
              key={`${card.key}-${option.value}`}
              id={`${card.key}-${option.value}`}
              value={option.value}
              label={option.label}
              checked={checked.includes(option.value)}
            />
          ))}
        </RadioGroup>

        <Mutation
          mutation={card.mutation}
          variables={mutationVariables()}
          onError={(err: ApolloError) => console.error(err)}
        >
          {(mutation: any) => (
            <Button fluid onClick={() => handleAnswer(checked as string[], mutation)}>
              Volgende
            </Button>
          )}
        </Mutation>
      </>
    );
  }
  if (card.type === 'consumption') {
    return (
      <ConsumptionQuestion
        answered={answered}
        card={card}
        onAnswer={onAnswer}
        saveAnswerInState={saveAnswerInState}
        savedAnswers={answers}
        shouldFetch={status === 'current'}
      />
    );
  }
  if (card.type === 'final') {
    return (
      <FinalQuestion
        askForEmail={askForEmail}
        onFinish={onFinish}
        emailRequired={emailRequired && !fromGVA}
        emailValue={emailValue}
        setEmail={setEmail}
        shouldFetch={status === 'current'}
      />
    );
  }
  return <></>;
};

export default BCQuestionInput;
