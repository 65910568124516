import React from 'react';
import { SolutionInfoType } from './infoContent';

const weatherStripping = (infoType: SolutionInfoType) => (
  <div>
    {infoType === 'general' && (
      <p>
        Kierdichting is het afsluiten van naden en kieren rond kozijnen van ramen en deuren en
        andere openingen om tocht te verminderen. Dit kan met verschillende materialen gedaan
        worden, waaronder tochtstrips, tochtbanden en kit. Kierdichting is een relatief kleine
        ingreep die goed zelf te doen is. De materialen zijn namelijk bij de meeste bouwmarkten te
        koop.
      </p>
    )}
    {infoType === 'help' && (
      <>
        <div className="h5 bold">Er is maar een deel van de naden en kieren gedicht, wat nu?</div>
        <p>
          Beantwoord de vraag met <span className="bold">nee</span>. Als je na de Bespaarcheck
          persoonlijk advies aanvraagt, zullen wij je situatie beter in kaart brengen en wordt
          aanvullende kierdichting niet over het hoofd gezien.
        </p>
      </>
    )}
  </div>
);

export default weatherStripping;
