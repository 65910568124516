import { gql } from '@apollo/client';

const ResultsTextFragment = gql`
  fragment ResultsText on SavingsCheckResultsText {
    default
    defaultOverride
    infeasible
    extra
    extraInsulation
  }
`;

export const ResultsTextsFragment = gql`
  ${ResultsTextFragment}
  fragment ResultsTexts on SavingsCheckResultsTexts {
    wallInsulation {
      ...ResultsText
    }
    floorInsulation {
      ...ResultsText
    }
    crawlspaceInsulation {
      ...ResultsText
    }
    innerRoofInsulation {
      ...ResultsText
    }
    atticFloorInsulation {
      ...ResultsText
    }
    windowGlazing {
      ...ResultsText
    }
    windowFrames {
      ...ResultsText
    }
    pvSystem {
      ...ResultsText
    }
    centralHeatingBoiler {
      ...ResultsText
    }
    hybridHeatPump {
      ...ResultsText
    }
    electricHeatPump {
      ...ResultsText
    }
    airToAirHeatPump {
      ...ResultsText
    }
    miscellaneous {
      ...ResultsText
    }
    sedum {
      ...ResultsText
    }
    chargingBox {
      ...ResultsText
    }
  }
`;
