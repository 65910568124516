import React from 'react';
import { SolutionInfoType } from './infoContent';

const roofInsulation = (infoType: SolutionInfoType) => (
  <div>
    {infoType === 'general' && (
      <p>
        Gemiddeld beslaat het dakoppervlak 30% van het totale oppervlak dat in verbinding staat met
        buiten. En aangezien warmte stijgt, is dakisolatie een efficiënte manier om de warmte binnen
        te houden. Onder dakisolatie verstaan we verschillende vormen van isolatie. Je kunt het dak
        zelf aan de binnen- en/of buitenkant isoleren, maar je kunt ook de zoldervloer isoleren,
        mits de zolder niet als leefruimte wordt gebruikt.
      </p>
    )}
    {infoType === 'help' && (
      <div>
        <div className="h5 bold">Hoe weet ik of mijn dak al geïsoleerd is?</div>
        <ul className="no-top-margin">
          <li>
            In de verkoopbrochure, het bouwkundig rapport of het taxatierapport van de woning staat
            meestal of het dak geïsoleerd is
          </li>
        </ul>
        <div>Voor een schuin dak:</div>
        <ul className="no-top-margin">
          <li>
            Controleer of er tussen de houten balken aan de binnenzijde isolatiemateriaal aanwezig
            is (als het afgewerkt is kun je achter schotten of bij andere onafgewerkte delen kijken
            zoals rookgasafvoer)
          </li>
          <li>
            Controleer of je isolatiemateriaal kunt zien tussen de dakpannen en de houten
            constructie (bijvoorbeeld vanuit een dakraam)
          </li>
        </ul>
        <div>Voor een plat dak:</div>
        <ul className="no-top-margin">
          <li>Controleer of er isolatiemateriaal op de dakbedekking ligt</li>
        </ul>
      </div>
    )}
  </div>
);

export default roofInsulation;
