import { Box, Flex, Icon, Tooltip } from '@energiebespaarders/symbols';
import { Smaller } from '@energiebespaarders/symbols/helpers';
import { Check } from '@energiebespaarders/symbols/icons/solid';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledNumber = styled(Box)<{ $isActive: boolean; $isDisabled: boolean; $bgColor?: string }>`
  position: relative;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  vertical-align: top;
  transition: all 0.25s;
  border-style: solid;
  border-radius: 100px;
  font-weight: 600;
  border-width: 1px;
  border-color: ${x => x.$bgColor};
  color: ${x => x.$bgColor};

  ${mediaMin.md} {
    width: ${x => x.theme.type.scale[2]};
    height: ${x => x.theme.type.scale[2]};
    line-height: ${x => x.theme.type.scale[2]};
    font-size: ${x => x.theme.type.scale[4]};
  }

  ${x =>
    x.$isActive &&
    css`
      border: none;
      color: white;
      background: ${x.$bgColor};
    `};

  ${x =>
    x.$isDisabled &&
    css`
      border: none;
      color: white;
      background: ${x.theme.colors.gray};
    `};
`;

type ProcessStep = {
  order?: number;
  name: string;
  tooltip?: string;
};
interface ProcessIndicatorProps {
  activeStep: number;
  disabled?: number[];
  completed?: number[];
  steps?: ProcessStep[];
  bgColor?: string;
}

const bcSteps: ProcessStep[] = [
  {
    order: 1,
    name: 'Bespaarcheck',
    tooltip:
      'Bereken aan de hand van een aantal simpele vragen de bespaarmogelijkheden voor jouw woning',
  },
  {
    order: 2,
    name: 'Resultaten',
    tooltip: 'Ontdek wat de verschillende oplossingen voor jouw situatie kunnen betekenen',
  },
  {
    order: 3,
    name: 'Persoonlijk advies',
    tooltip: 'Laat iemand langskomen en ontvang persoonlijk advies van onze adviseurs',
  },
];

const ProcessIndicator: React.FC<ProcessIndicatorProps> = ({
  activeStep,
  completed = [],
  disabled = [],
  steps = bcSteps,
  bgColor = 'green',
}) => (
  <Flex textAlign="center" py={[3, 5]} mx="auto" justifyContent="center">
    {steps.map((step, index) => {
      const stepNumber = step.order || index + 1;
      const Step = (
        <>
          <StyledNumber
            aria-haspopup={step.tooltip ? 'true' : 'false'}
            $isActive={stepNumber === activeStep || completed.includes(stepNumber)}
            $isDisabled={disabled.includes(stepNumber)}
            $bgColor={themify(bgColor)}
          >
            {completed.includes(stepNumber) ? (
              <Icon
                icon={Check}
                solid
                fill="white"
                verticalAlign="middle"
                style={{ position: 'relative', top: '-2px' }}
              />
            ) : (
              stepNumber
            )}
          </StyledNumber>
          <div
            style={{
              whiteSpace: 'nowrap',
              fontWeight: stepNumber === activeStep ? 600 : 400,
            }}
          >
            <Smaller>{step.name}</Smaller>
          </div>
        </>
      );
      return (
        <Box key={`${stepNumber || index + 1}-${step.name}`} width={[1 / 3, 1 / 4, 1 / 5]}>
          {step.tooltip ? (
            <Tooltip content={step.tooltip} placement="bottom">
              {Step}
            </Tooltip>
          ) : (
            Step
          )}
        </Box>
      );
    })}
  </Flex>
);

export default ProcessIndicator;
