import { Modal } from '@energiebespaarders/symbols';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import React from 'react';
import { QuestionKey } from '../SavingsCheck/getCards';
import getContent from './infoContent';

function openInNewTab(url: string) {
  const buttonLink = document.createElement('a');
  buttonLink.href = url;
  buttonLink.target = '_blank';
  document.body.appendChild(buttonLink);
  buttonLink.click();
  document.body.removeChild(buttonLink);
}

interface SolutionInfoModalProps {
  closeModal: () => void;
  constructionYear: number;
  isOpen: boolean;
  questionKey: QuestionKey | '';
  showHelp: boolean;
  solutionNL: string;
}
const SolutionInfoModal: React.FC<SolutionInfoModalProps> = ({
  closeModal,
  constructionYear,
  isOpen,
  questionKey,
  showHelp,
  solutionNL,
}) => {
  const mobile = useIsMobile();
  let inlineSolution = solutionNL;
  if (questionKey === 'boilerAge') inlineSolution = 'CV-ketels';
  if (questionKey === 'hasWallCavity') inlineSolution = 'spouwmuurisolatie';
  if (questionKey === 'hasCrawlspace') inlineSolution = 'kruipruimtes';
  return (
    <Modal
      mobile={mobile}
      isOpen={isOpen && Boolean(questionKey)}
      title={`Meer informatie over ${inlineSolution}`}
      buttons={[
        {
          onClick: closeModal,
          bgColor: 'red',
          inverse: true,
          label: 'Sluiten',
        },
        {
          onClick: () => openInNewTab(`/woning-verduurzamen/product/${solutionNL?.toLowerCase()}`),
          inverse: true,
          label: 'Meer lezen',
        },
      ]}
      onRequestClose={closeModal}
      width="md"
    >
      {getContent(questionKey, 'general', constructionYear)}
      {showHelp && getContent(questionKey, 'help', constructionYear)}
    </Modal>
  );
};

export default SolutionInfoModal;
