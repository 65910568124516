import React from 'react';
import { SolutionInfoType } from './infoContent';

const insulationGlazing = (infoType: SolutionInfoType, constructionYear: number) => (
  <div>
    {infoType === 'general' && (
      <div>
        <p>
          Isolatieglas is de verzamelnaam voor alle vormen van dubbel en HR-glas
          (hoogrendementsglas). HR-glas isoleert beter dan standaard dubbelglas, dankzij coatings
          en/of edelgassen. Er zijn verschillende typen HR-glas te onderscheiden:
        </p>
        <ul className="no-top-margin">
          <li>
            <strong>HR</strong>: dubbelglas met een gecoat buitenblad; de ruimte ertussen is gevuld
            met droge lucht
          </li>
          <li>
            <strong>HR+</strong>: dubbelglas met een gecoat buitenblad; de ruimte ertussen is gevuld
            met een edelgas (meestal argon)
          </li>
          <li>
            <strong>HR++</strong>: in feite hetzelfde als HR+, maar met een grotere afstand (vanaf
            15mm) tussen de glasplaten{' '}
          </li>
          <li>
            <strong>HR+++</strong>: alle vormen van driedubbelglas vallen onder HR+++ (ookwel HR3),
            ongeacht coatings en edelgassen
          </li>
        </ul>
      </div>
    )}
    {infoType === 'help' && (
      <>
        {constructionYear >= 1965 ? (
          <>
            <div className="h5 bold">Hoe weet ik wat voor glas ik heb?</div>
            <ul className="no-top-margin">
              <li>
                Controleer of er op de aluminiumstrip tussen de glasplaten een HR-codering staat
                (als er wel een code staat maar geen 'HR', is het meestal dubbelglas)
              </li>
              <li>
                Houd een aansteker voor het glas en kijk er schuin op. Voor elke glasplaat zie je 2
                reflecties van het vlammetje. Hebben alle vier de vlammetjes dezelfde kleur, dan is
                het standaard dubbelglas. Heeft het tweede of derde vlammetje een iets andere kleur?
                Dan is het HR-glas.
              </li>
              <li>
                In de verkoopbrochure, het bouwkundig rapport of het taxatierapport van de woning
                staat meestal wat voor type glas er geplaatst is
              </li>
            </ul>
          </>
        ) : (
          <>
            <div className="h5 bold">Hoe weet ik of ik enkelglas heb?</div>
            <p>
              Enkelglas is gemakkelijk te herkennen, omdat het &ndash; zoals de naam al zegt &ndash;
              uit een enkele glaspaneel bestaat. Bij dubbelglas of beter is er een aluminiumstrip
              tussen de glasplaten zichtbaar.
            </p>
          </>
        )}
      </>
    )}
  </div>
);

export default insulationGlazing;
