import { useLazyQuery, useMutation } from '@apollo/client';
import {
  EnergyLabel as EnergyLabelType,
  HOUSE_TYPE_CATEGORIES_NL,
  HOUSE_TYPE_IDS,
  HouseType,
  getPreliminaryEnergyLabel,
} from '@energiebespaarders/constants';
import {
  Box,
  Button,
  Card,
  EnergyLabel,
  Flex,
  Icon,
  Input,
  Select,
  Spinner,
  Tooltip,
} from '@energiebespaarders/symbols';
import { Book, Center, Heading, Small } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { Information } from '@energiebespaarders/symbols/icons/solid';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import LoadError from '~/components/LoadError';
import { ADDRESS_CARD } from '~/queries/house';
import { UPDATE_BASIC_HOUSE_PROPERTIES } from '../../envPartner/PartnerCheck/UpdateHouseInfoForm';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import { useMeOptional } from '../../hooks/useMe';
import {
  addressCard,
  addressCardVariables,
  addressCard_house,
} from '../../types/generated/addressCard';
import { houseIsVVE, houseIsVVEVariables } from '../../types/generated/houseIsVVE';
import {
  updateBasicHouseProperties,
  updateBasicHousePropertiesVariables,
} from '../../types/generated/updateBasicHouseProperties';
import { AddressInput } from '../../types/graphql-global-types';
import SavingsCheckForm from '../SavingsCheckForm';
import { UPDATE_HOUSE_IS_VVE } from './mutations';

export const houseTypeOptions = [
  { value: 1, label: 'vrijstaande woning' },
  { value: 2, label: 'twee-onder-één-kapwoning' },
  { value: 3, label: 'hoekwoning' },
  { value: 4, label: 'tussenwoning' },
  { value: 5, label: 'appartement' },
];

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const options = [];
  for (let i = 1800; i <= currentYear; i++) options.push(i);
  return options;
};

export const yearOptions = getYears().map(year => ({
  label: year.toString(),
  value: year,
}));

interface IAddressQuestionProps {
  handleStartBC: (values: AddressInput) => void;
  initializing: boolean;
  isEbvCheck: boolean;
  startQuestions: () => void;
  startCount: number;
}

export const AddressQuestion: React.FC<IAddressQuestionProps> = ({
  handleStartBC,
  initializing,
  isEbvCheck,
  startQuestions,
  startCount,
}) => {
  const { me } = useMeOptional();
  const mobile = useIsMobile();
  const { activeHouseId, setActiveHouseId } = useActiveHouseId();
  const isAuthenticated = useIsAuthenticated();
  const [enterAddress, setEnterAddress] = useState(false);
  const [adjustedType, setAdjustedType] = useState<number | null>(null);
  const [adjustedConstructionYear, setAdjustedConstructionYear] = useState<number | null>(null);
  const [adjustedArea, setAdjustedArea] = useState<number | null>(null);
  const [editing, setEditing] = useState(false);

  const router = useRouter();

  const [updateHouseProps] = useMutation<
    updateBasicHouseProperties,
    updateBasicHousePropertiesVariables
  >(UPDATE_BASIC_HOUSE_PROPERTIES);
  const [updateVve] = useMutation<houseIsVVE, houseIsVVEVariables>(UPDATE_HOUSE_IS_VVE);
  const [getHouseQuery, { data: cardData, loading: queryLoading, error }] = useLazyQuery<
    addressCard,
    addressCardVariables
  >(ADDRESS_CARD);

  const [addressFromParams, setAddressFromParams] = useState<{
    zip: string;
    number: number;
    suffix: string;
  }>({ zip: '', number: 0, suffix: '' });
  const params: { postcode?: string; huisnummer?: string; toevoeging?: string } = router.query;

  const fromTriodos =
    me?.__typename === 'Lead' && (me.landingPage === '/triodos' || me.referrer.includes('triodos'));

  useEffect(() => {
    if (!addressFromParams.zip && params.postcode) {
      if (activeHouseId && startCount === 0) setActiveHouseId('');
      setAddressFromParams(a => ({ ...a, zip: params.postcode?.toUpperCase() ?? '' }));
    }
    if (!addressFromParams.number && params.huisnummer) {
      setAddressFromParams(a => ({ ...a, number: parseInt(params.huisnummer || '0') }));
    }
    if (!addressFromParams.suffix && params.toevoeging) {
      setAddressFromParams(a => ({ ...a, suffix: params.toevoeging || '' }));
    }
  }, [addressFromParams, params, startCount]); // eslint-disable-line

  // If there is an activeHouseId already, use that on load
  useEffect(() => {
    if (activeHouseId) {
      void getHouseQuery({ variables: { id: activeHouseId } });
      if (enterAddress) {
        setEnterAddress(false);
      }
    }
  }, [activeHouseId, getHouseQuery, startCount]); // eslint-disable-line

  const handleEditAddress = useCallback(() => setEnterAddress(true), []);
  const renderCardWithAddress = useCallback(
    (house: addressCard_house) => {
      const {
        address,
        area,
        constructionYear,
        labelRegistration,
        type,
        vve,
        initialQuestionsAnswered,
      } = house;
      const { street, number, suffix, zip, city } = address;
      const houseType = HOUSE_TYPE_CATEGORIES_NL[HOUSE_TYPE_IDS[`type${type}` as HouseType]];
      const registeredLabel = labelRegistration?.energyLabel;

      const hideYear =
        constructionYear === 1005 ||
        constructionYear === 9999 ||
        constructionYear > new Date().getFullYear();
      const handleUpdateHouseProperties = () => {
        void updateHouseProps({
          variables: {
            houseId: activeHouseId,
            type: Number(adjustedType || type),
            area: Number(adjustedArea ? Math.max(1, adjustedArea) : area),
            constructionYear: Number(
              adjustedConstructionYear
                ? Math.max(1500, adjustedConstructionYear)
                : constructionYear,
            ),
          },
        });
        setEditing(false);
      };
      return (
        <div className="text-center">
          {initialQuestionsAnswered && (
            <p className="text-center">
              Als je de Bespaarcheck opnieuw start, worden de opgeslagen resultaten overschreven.
            </p>
          )}
          {!isAuthenticated.asCustomer && (
            <div className="text-right">
              <div className="inline-block small link-gray" onClick={handleEditAddress}>
                Ander adres gebruiken
              </div>
            </div>
          )}
          <Card shadow="none" borderColor="grayLight">
            <div className="text-center">
              <Book>
                {street} {number}
                {suffix && ` ${suffix}`}, {zip} {city}
              </Book>
            </div>
            {editing ? (
              <Flex alignItems="center" flexWrap="wrap" mx={-1}>
                <Box width={[1 / 4, 1 / 8]} px={1}>
                  een
                </Box>
                <Box width={[3 / 4, 3 / 8]}>
                  <Select<number>
                    onChange={e => setAdjustedType(e.value)}
                    options={houseTypeOptions}
                    fontSize={6}
                    clearable={false}
                    value={houseTypeOptions.find(
                      option => option.value === adjustedType || option.value === Number(houseType),
                    )}
                  />
                </Box>
                <Box width={[1 / 4, 1 / 8]} px={1}>
                  van
                </Box>
                <Box width={[3 / 4, 3 / 8]}>
                  <Input
                    addonSide="end"
                    addonContent="m2"
                    type="number"
                    minLength={2}
                    maxLength={4}
                    value={adjustedArea || area}
                    onChange={e => setAdjustedArea(parseInt(e.target.value))}
                    placeholder="Woonoppervlak"
                    inputMode="numeric"
                  />
                </Box>
                {!hideYear && (
                  <>
                    <Box width={[1 / 4, 1 / 8]} px={1}>
                      uit
                    </Box>
                    <Box width={[3 / 4, 3 / 8]}>
                      <Select<number>
                        value={yearOptions.find(
                          option =>
                            option.value === adjustedConstructionYear ||
                            option.value === constructionYear,
                        )}
                        onChange={e => setAdjustedConstructionYear(e.value)}
                        options={yearOptions}
                        fontSize={6}
                      />
                    </Box>
                  </>
                )}
              </Flex>
            ) : (
              <div className="text-center">
                Een <Book>{houseType.toLowerCase()}</Book> van{' '}
                <Book>
                  {area} m<sup>2</sup>
                </Book>{' '}
                {!hideYear && (
                  <>
                    uit <Book>{constructionYear}</Book>
                  </>
                )}
                {registeredLabel ? (
                  <>
                    {' '}
                    met energielabel{' '}
                    <EnergyLabel label={registeredLabel as EnergyLabelType} fontSize={7} />
                  </>
                ) : fromTriodos ? (
                  <>
                    {' '}
                    met voorlopig energielabel{' '}
                    <EnergyLabel
                      label={
                        getPreliminaryEnergyLabel(
                          adjustedType || type,
                          adjustedConstructionYear || constructionYear,
                        ) as EnergyLabelType
                      }
                      fontSize={7}
                    />
                    <Tooltip content="Een voorlopig energielabel is het energielabel dat de woning heeft toegewezen gekregen op basis van het bouwjaar en type van de woning. Deze wordt niet officieel erkend bij een woningoverdracht.">
                      <Icon icon={Information} mx={1} fill="blue" />
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}

            <Box width={1} mt={1}>
              <Book>
                <input
                  type="checkbox"
                  checked={!!vve}
                  id="houseVve"
                  onChange={() =>
                    updateVve({
                      variables: {
                        houseId: activeHouseId,
                        isVve: !vve,
                      },
                    })
                  }
                  style={{ marginRight: 6, verticalAlign: 'middle' }}
                />
                <Small>
                  <label htmlFor="houseVve">De woning is onderdeel van een VVE</label>
                </Small>
              </Book>
            </Box>
          </Card>

          <div>
            <p className="inline-block small link-gray" onClick={() => setEditing(e => !e)}>
              {!editing ? 'Gegevens aanpassen' : 'Annuleren'}
            </p>
            <Box className="inline-block" mr={1} />
            {editing && (
              <div
                className="inline-block small link-green bold"
                onClick={handleUpdateHouseProperties}
              >
                Opslaan
              </div>
            )}
          </div>

          {initialQuestionsAnswered ? (
            <Center block>
              <Button
                fluid={mobile}
                onClick={() =>
                  router.push(isEbvCheck ? '/bespaarmogelijkheden-ebv' : '/bespaarmogelijkheden')
                }
              >
                Naar resultaten
              </Button>
              <Button fluid={mobile} onClick={startQuestions}>
                Overschrijven en doorgaan
              </Button>
            </Center>
          ) : (
            <Button fluid onClick={startQuestions} disabled={editing}>
              Starten →
            </Button>
          )}
        </div>
      );
    },
    [
      activeHouseId,
      adjustedArea,
      adjustedConstructionYear,
      adjustedType,
      editing,
      fromTriodos,
      handleEditAddress,
      router,
      isAuthenticated.asCustomer,
      isEbvCheck,
      mobile,
      startQuestions,
      updateHouseProps,
      updateVve,
    ],
  );

  if (queryLoading) return <Spinner />;
  if (error) return <LoadError error={error} />;
  const house = cardData?.house;

  return (
    <>
      <Heading heading="h4" fontWeight={500} textAlign="center">
        Welkom bij de Bespaarcheck!
      </Heading>
      {enterAddress || !activeHouseId ? (
        <>
          <p>
            <Book>Vul je postcode en huisnummer in om te beginnen</Book>
          </p>
          <SavingsCheckForm
            buttonText="Woninggegevens ophalen"
            isLoading={initializing}
            onSubmit={handleStartBC}
            labelColor="grayDark"
            address={cardData?.house ? cardData.house.address : addressFromParams}
          />
          {enterAddress && activeHouseId && cardData && (
            <Button
              label={`Doorgaan met ${cardData.house.address.street} ${cardData.house.address.number} ${cardData.house.address.suffix}`}
              bgColor="grayDark"
              fluid
              minimal
              fontSize={6}
              onClick={() => setEnterAddress(false)}
            />
          )}
        </>
      ) : house ? (
        renderCardWithAddress(house)
      ) : (
        <></>
      )}
    </>
  );
};

export default AddressQuestion;
