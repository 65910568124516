import React from 'react';
import { SolutionInfoType } from './infoContent';

const hasCrawlspace = (infoType: SolutionInfoType) => (
  <div>
    {infoType === 'help' && (
      <div>
        <div className="h5 bold">Hoe weet ik of mijn woning een kruipruimte heeft?</div>
        <p>
          Woningen gebouwd na 1930 hebben meestal een kruipruimte. Dit is een ruimte onder de begane
          grond van gemiddeld 50cm hoog. Controleer of je de luik naar de kruipruimte kunt vinden
          (deze is soms weggewerkt onder de vloer)
        </p>
      </div>
    )}
  </div>
);

export default hasCrawlspace;
