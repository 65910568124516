import { useLazyQuery, useMutation } from '@apollo/client';
import { Solution } from '@energiebespaarders/constants';
import {
  Box,
  Button,
  Container,
  Counter,
  Flex,
  Input,
  Separator,
  Spinner,
} from '@energiebespaarders/symbols';
import { Book, Color, Green, Heading, Smaller } from '@energiebespaarders/symbols/helpers';
import { useRouter } from 'next/router';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getLocalJourneyData } from '~/components/RequestPackage/utils';
import { SEND_RESULTS_PDF } from '~/components/Results/ResultSender';
import { RESULTS_CARDS_STANDALONE } from '~/components/Results/queries';
import useABTest from '~/hooks/useABTest';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import { useLeadEmail } from '~/hooks/useLeadEmail';
import { usePersonalEnergyPrices } from '~/hooks/usePersonalEnergyPrices';
import {
  finishInitialQuestions,
  finishInitialQuestionsVariables,
} from '~/types/generated/finishInitialQuestions';
import {
  resultsCardsStandalone,
  resultsCardsStandaloneVariables,
} from '~/types/generated/resultsCardsStandalone';
import { sendResultsPDF, sendResultsPDFVariables } from '~/types/generated/sendResultsPDF';
import delimit from '~/utils/delimit';
import { calculateRelativeTotals } from '~/utils/relativeTotals';
import { ignoredSolutions } from '../Results/ResultCards';
import { getResultsCardTexts } from '../Results/utils';
import { FINISH_INITIAL_QUESTIONS } from './mutations';

interface FinalQuestionProps {
  askForEmail: boolean;
  emailRequired: boolean;
  onFinish: () => void;
  setEmail: Dispatch<SetStateAction<string>>;
  emailValue: string;
  shouldFetch: boolean;
}

const FinalQuestion: React.FC<FinalQuestionProps> = ({
  askForEmail,
  emailRequired,
  onFinish,
  setEmail,
  emailValue,
  shouldFetch,
}) => {
  const { activeHouseId } = useActiveHouseId();
  const { personalEnergyPrices } = usePersonalEnergyPrices();
  const journeyData = getLocalJourneyData();
  const { setLeadEmail } = useLeadEmail();
  const { abTestEntries } = useABTest();
  const [potentialSavings, setPotentialSavings] = useState(0);
  const router = useRouter();

  /** NOTE: Determines whether to show positive or negative frame */
  const showPositiveFrame = useMemo(
    () =>
      abTestEntries?.some(
        entry => entry.domain === 'bespaarcheck' && entry.variant.includes('positive-gain'),
      ),
    [abTestEntries],
  );
  /** NOTE: Determines whether to show monthly savings */
  const inGroupB = useMemo(
    () => abTestEntries?.some(entry => entry.variant === 'deb-savings-monthly'),
    [abTestEntries],
  );

  const [getPotentialSavings, { data, loading: loadingResults }] = useLazyQuery<
    resultsCardsStandalone,
    resultsCardsStandaloneVariables
  >(RESULTS_CARDS_STANDALONE, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (shouldFetch) {
      void getPotentialSavings({
        variables: { houseId: activeHouseId, isCustomer: false, isLead: true },
      });
    }
  }, [activeHouseId, getPotentialSavings, shouldFetch]);

  const resultCardTexts = useMemo(
    () => (data?.house ? getResultsCardTexts(data.house) : undefined),
    [data],
  );

  useEffect(() => {
    if (resultCardTexts && shouldFetch && data?.house) {
      const house = data?.house;
      const consumption = {
        gas: house.situation.consumption.gas ?? house.energy.gasConsumption,
        electricity:
          house.situation.consumption.electricity ??
          house.energy.electricityConsumption - house.energy.electricityProduction,
      };
      const feasibleSolutions = (Object.keys(resultCardTexts) as Solution[]).filter(
        s => !resultCardTexts[s].infeasible && !ignoredSolutions.includes(s),
      );
      const feasibleEstimates = (data?.house.estimates || []).filter(estimate =>
        feasibleSolutions.includes(estimate.solution),
      );
      const totalSavings = Math.max(
        0,
        Math.round(
          calculateRelativeTotals(feasibleEstimates, consumption, personalEnergyPrices).money / 10,
        ) * 10,
      );
      if (totalSavings !== potentialSavings) {
        setPotentialSavings(inGroupB ? totalSavings / 12 : totalSavings);
      }
    }
  }, [data?.house, inGroupB, personalEnergyPrices, potentialSavings, resultCardTexts, shouldFetch]);

  const ebvCheck = useMemo(() => router.asPath.includes('-ebv'), [router.asPath]);
  const [sendResults] = useMutation<sendResultsPDF, sendResultsPDFVariables>(SEND_RESULTS_PDF);
  const [finishBespaarcheck, { loading }] = useMutation<
    finishInitialQuestions,
    finishInitialQuestionsVariables
  >(FINISH_INITIAL_QUESTIONS, {
    variables: {
      houseId: activeHouseId,
      email: emailValue,
      journeyData,
    },
    onCompleted: data => {
      if (askForEmail && emailValue) {
        void sendResults({
          variables: {
            email: emailValue,
            houseId: activeHouseId,
            forEbv: ebvCheck,
            journeyData,
          },
        });
      }
      data?.finishInitialQuestions?.initialQuestionsAnswered && onFinish();
    },
  });

  const handleFinish = useCallback(() => finishBespaarcheck(), [finishBespaarcheck]);

  const handleChangeEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      setLeadEmail(e.target.value);
    },
    [setEmail, setLeadEmail],
  );

  const handleSubmit = useCallback((e: React.FormEvent) => e.preventDefault(), []);

  return (
    <Container size="md" textAlign="center" px={5}>
      <form onSubmit={handleSubmit}>
        {loadingResults ? (
          <Spinner />
        ) : (
          <>
            <Heading heading="h3">
              {showPositiveFrame ? 'Je bespaart' : 'Je verspilt'}{' '}
              <Green>
                €<Counter value={potentialSavings} formatValue={n => delimit(n, 0)} />
              </Green>{' '}
              per {inGroupB ? 'maand' : 'jaar'}
            </Heading>
            <Book>
              <p>{showPositiveFrame ? 'We vertellen je hoe.' : 'Dat kunnen we voorkomen.'}</p>
            </Book>
          </>
        )}
        <Separator color="grayLight" my={2} />
        <p>
          Er zijn verschillende bespaarmogelijkheden. Op de volgende pagina lees je welke dat zijn
          en leggen we uit hoe ze werken.
        </p>
        {askForEmail ? (
          <Flex flexWrap="wrap" alignItems="flex-end" mx={-1}>
            <Box width={1} px={1}>
              <p>
                Vul hier{!emailRequired ? ' alvast ' : ' '}je e-mailadres in om direct een overzicht
                te ontvangen.
              </p>
            </Box>
            <Box width={[1, 2 / 3, 3 / 4]} px={1} mt={1}>
              <Input
                id="bcEmail"
                type="email"
                placeholder="Vul hier je e-mailadres in om een overzicht te ontvangen"
                label={emailRequired ? 'E-mailadres *' : 'E-mailadres'}
                onChange={handleChangeEmail}
                value={emailValue}
              />
            </Box>
            <Box width={[1, 1 / 3, 1 / 4]} px={1}>
              <Button
                fluid
                loading={loading}
                type="submit"
                disabled={
                  emailRequired && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue)
                }
                onClick={handleFinish}
                label="Help mij besparen"
              />
            </Box>
            <Box width={[1, 2 / 3, 3 / 4]} px={1}>
              <Smaller>
                <p>
                  <Color c="grayDark">
                    Je accepteert hiermee{' '}
                    <a href="/privacy" title="Lees onze privacyverklaring" target="_blank">
                      onze privacyverklaring
                    </a>{' '}
                    en geeft toestemming om informatie van ons te ontvangen. Je kunt je altijd
                    afmelden.
                  </Color>
                </p>
              </Smaller>
            </Box>
          </Flex>
        ) : (
          <Button
            fluid
            loading={loading}
            type="submit"
            onClick={handleFinish}
            label="Help mij besparen"
          />
        )}
      </form>
    </Container>
  );
};

export default FinalQuestion;
