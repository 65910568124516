import { ApolloError, useLazyQuery } from '@apollo/client';
import useForm, { isInvalidPostcode, isMissing } from '@energiebespaarders/hooks/useForm';
import { Box, Button, Flex, Icon, Tooltip } from '@energiebespaarders/symbols';
import { Alert } from '@energiebespaarders/symbols/icons/solid';
import React, { useEffect, useMemo, useState } from 'react';
import { FIND_ADDRESS_FUNCTION, GET_SUFFIXES } from '../queries/house';
import {
  findAddressFunction,
  findAddressFunctionVariables,
} from '../types/generated/findAddressFunction';
import { getSuffixes, getSuffixesVariables } from '../types/generated/getSuffixes';
import { BasicAddressInput } from '../types/graphql-global-types';
import AddressFields, { FormAddress } from './AddressFields';
import { NON_RESIDENTIAL_MESSAGE, getAddressFieldsError } from './AddressFields/utils';

interface SavingsCheckFormProps {
  address?: BasicAddressInput | null;
  disabled?: boolean;
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  onSubmit: (address: BasicAddressInput, cb?: any) => void;
  error?: ApolloError;
  showPreExistingHouseError?: boolean;
  labelColor?: string;
  onAddressValid?: (args?: any) => void;
}

const SavingsCheckForm: React.FC<SavingsCheckFormProps> = ({
  address,
  disabled,
  isLoading,
  title,
  subtitle,
  buttonText = 'Start →',
  onSubmit,
  showPreExistingHouseError = true,
  error,
  labelColor,
  onAddressValid,
}) => {
  const [addressError, setAddressError] = useState('');
  const [apolloError, setApolloError] = useState<ApolloError | undefined>(error);
  const [shouldValidateAddress, setShouldValidateAddress] = useState(false);
  const { formState, handleChange, submitForm, submissionAttempted } = useForm<FormAddress>({
    initialValues: {
      zip: address?.zip || '',
      number: address?.number,
      suffix: address?.suffix || '',
    },
    handleSubmit: values => {
      if (!addressError) onSubmit(values);
    },
    validate: (values, errors) => {
      if (isMissing(values.zip) || isInvalidPostcode(values.zip)) {
        errors.zip = 'Postcode ongeldig';
      }
      if (isMissing(values.number) || values.number === 0) {
        errors.number = 'Nummer ongeldig';
      }
      return errors;
    },
  });

  useEffect(() => {
    if (!formState.zip.touched && !formState.zip.value && address?.zip) {
      handleChange({ zip: address.zip });
    }
    if (!formState.number.touched && !formState.number.value && address?.number) {
      handleChange({ number: address.number });
    }
    if (!formState.suffix?.touched && !formState.suffix?.value && address?.suffix) {
      handleChange({ suffix: address.suffix });
    }
  }, [address, formState, handleChange]);

  const [fetchSuffixes, { data: suffixesData, loading: suffixesLoading }] = useLazyQuery<
    getSuffixes,
    getSuffixesVariables
  >(GET_SUFFIXES, {
    fetchPolicy: 'network-only',
    variables: {
      address: { zip: formState.zip.value, number: formState.number.value || 0 },
    },
    onError: e => setApolloError(e),
  });

  const [fetchAddressFunction, { data: addressFunctionData }] = useLazyQuery<
    findAddressFunction,
    findAddressFunctionVariables
  >(FIND_ADDRESS_FUNCTION, {
    fetchPolicy: 'network-only',
    variables: {
      address: {
        zip: formState.zip.value,
        number: formState.number.value || 0,
        suffix: formState.suffix?.value,
      },
    },
    onError: e => setApolloError(e),
  });

  const suffixFormStateValue = formState.suffix?.value || '';

  useEffect(() => {
    if (formState.zip.value && formState.zip.value.length === 6 && formState.number.value) {
      if (!shouldValidateAddress) setShouldValidateAddress(true);
      void fetchAddressFunction();
    }
  }, [
    formState.zip.value,
    formState.number.value,
    suffixFormStateValue,
    fetchAddressFunction,
    shouldValidateAddress,
  ]);

  useEffect(() => {
    if (formState.zip.value && formState.number.value) {
      void fetchSuffixes();
    }
  }, [formState.zip.value, formState.number.value, fetchSuffixes]);

  const suffixes: string[] = useMemo(
    () => [...(suffixesData?.getSuffixes?.suffixes || [])],
    [suffixesData],
  );

  useEffect(() => {
    if (shouldValidateAddress) {
      const err = getAddressFieldsError(
        formState,
        addressFunctionData,
        suffixes,
        apolloError,
        showPreExistingHouseError,
      );
      setAddressError(err);
      if (!err) onAddressValid?.();
    }
  }, [
    formState,
    addressFunctionData,
    apolloError,
    suffixes,
    shouldValidateAddress,
    showPreExistingHouseError,
    onAddressValid,
  ]);

  const isNonResidentialAddress = useMemo(
    () => addressError === NON_RESIDENTIAL_MESSAGE,
    [addressError],
  );

  return (
    <form onSubmit={submitForm}>
      {title && <div className="h4 no-top-margin">{title}</div>}
      {subtitle && <p>{subtitle}</p>}
      <AddressFields
        formState={formState}
        handleChange={handleChange}
        disabled={Boolean(disabled)}
        suffixes={suffixes}
        suffixesLoading={suffixesLoading}
        labelColor={labelColor}
      />
      <Flex>
        <Box width={1}>
          <Button
            type="submit"
            bgColor="yellow"
            color="purpleDarker"
            disabled={disabled}
            error={submissionAttempted ? addressError : undefined}
            fluid
            fontSize={disabled ? 6 : 5}
            label={isLoading ? 'Gegevens verzamelen...' : buttonText}
            loading={isLoading}
            loadTime={2}
            to={disabled ? '/bespaarcheck' : ''}
            style={{ zIndex: 0 }}
          />
        </Box>
        {submissionAttempted && isNonResidentialAddress && (
          <Box p={1}>
            <Tooltip
              bgColor="greenSlate"
              color="black"
              content="De Bespaarcheck kan op dit moment alleen voor adressen met een woonfunctie. Neem contact met ons op als je toch de Bespaarcheck wil doen voor dit adres."
            >
              <Icon icon={Alert} solid fill="greenSlate" width="9" />
            </Tooltip>
          </Box>
        )}
      </Flex>
    </form>
  );
};

export default SavingsCheckForm;
