import { QuestionKey } from './../SavingsCheck/getCards';
import centralHeating from './centralHeating';
import floorInsulation from './floorInsulation';
import hasCrawlspace from './hasCrawlspace';
import insulationGlazing from './insulationGlazing';
import pvSystem from './pvSystem';
import roofInsulation from './roofInsulation';
import wallCavity from './wallCavity';
import wallInsulation from './wallInsulation';
import weatherStripping from './weatherStripping';

export type SolutionInfoType = 'general' | 'help';
export function getContent(
  questionKey: QuestionKey | '',
  infoType: SolutionInfoType,
  constructionYear: number,
) {
  switch (questionKey) {
    case 'hasWallCavity':
      return wallCavity(infoType);
    case 'hasWallInsulation':
    case 'hasExtraWallInsulation':
      return wallInsulation(infoType, constructionYear);
    case 'hasCrawlspace':
      return hasCrawlspace(infoType);
    case 'hasFloorInsulation':
    case 'hasExtraFloorInsulation':
      return floorInsulation(infoType, constructionYear);
    case 'hasRoofInsulation':
    case 'hasExtraRoofInsulation':
      return roofInsulation(infoType);
    case 'panelAmount':
      return pvSystem(infoType);
    case 'livingGlassType':
    case 'sleepingGlassType':
      return insulationGlazing(infoType, constructionYear);
    case 'boilerAge':
      return centralHeating(infoType);
    case 'hasWeatherStripping':
      return weatherStripping(infoType);
    default:
      return '';
  }
}

export default getContent;
