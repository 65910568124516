import React from 'react';
import { SolutionInfoType } from './infoContent';

const centralHeating = (infoType: SolutionInfoType) => (
  <div>
    {infoType === 'general' && (
      <p>
        De levensduur van een CV-ketel is ongeveer 12 jaar. Hierna dient de ketel vervangen te
        worden. Er kunnen namelijk door slijtage lekkages ontstaan, die slecht zijn voor je
        gasverbruik, maar ook voor je gezondheid! Bovendien worden CV-ketels continu doorontwikkeld.
        Door een nieuwe CV-ketel te laten installeren, kun je dus een flinke vooruitgang boeken op
        energieverbruik en comfort!
      </p>
    )}
    {infoType === 'help' && (
      <>
        <div className="h5 bold">Hoe weet ik hoe oud mijn CV-ketel is?</div>
        <ul className="no-top-margin">
          <li>Meestal staat er een sticker op de ketel met daarop de installatiedatum</li>
          <li>
            Eventueel kun je herleiden wanneer die geplaatst is aan de hand van de eerste
            onderhoudsbeurt
          </li>
          <li>
            In de verkoopbrochure, het bouwkundig rapport of het taxatierapport van de woning staat
            meestal wanneer de ketel geplaatst is
          </li>
        </ul>
      </>
    )}
  </div>
);

export default centralHeating;
