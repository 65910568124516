import React from 'react';
import { SolutionInfoType } from './infoContent';

const wallInsulation = (infoType: SolutionInfoType, constructionYear: number) => (
  <div>
    {infoType === 'general' && (
      <p>
        Spouwmuurisolatie, ookwel gevelisolatie is het isoleren van de spouw: de ruimte tussen de
        binnen- en buitenmuur van je gevel. Deze ruimte is bedoeld om vochtproblemen te verminderen
        en kan gelukkig goed geïsoleerd worden als er goed geventileerd wordt. Daarmee zorgt de
        spouwmuur dat de kou buiten blijft en niet de spouw in kan stromen. Dat scheelt zo'n 30% tot
        35% op je stookkosten.
      </p>
    )}
    {infoType === 'help' && (
      <>
        {constructionYear >= 1975 && (
          <>
            <div className="h5 bold">Wat houdt na-isoleren precies in?</div>
            <p>
              Na-isolatie is het extra isoleren na de bouw. Woningen gebouwd na 1975 zijn bij de
              bouw voorzien van spouwmuurisolatie. Echter zijn veel van de materialen die toen zijn
              gebruikt ondertussen grotendeels vervallen en toe aan vernieuwing. Bovendien zijn de
              moderne isolatiematerialen milieuvriendelijker, duurzamer en hebben ze hogere
              isolatiewaarden. Na-isolatie levert meestal geen significant rendement op, maar
              verbetert wel het wooncomfort en voorkomt nadelige gevolgen van oudere
              isolatiematerialen.
            </p>
            <div className="h5 bold">Hoe weet ik of mijn gevel nageïsoleerd is?</div>
            <ul className="no-top-margin">
              <li>
                In de verkoopbrochure, het bouwkundig rapport of het taxatierapport van de woning
                staat meestal of de gevel nageïsoleerd is
              </li>
            </ul>
          </>
        )}
        <div className="h5 bold">Hoe weet ik of mijn gevels al geïsoleerd zijn?</div>
        <ul className="no-top-margin">
          <li>
            Als er plastic of metalen ventilatieroosters zijn, is de spouw in de gevel
            hoogstwaarschijnlijk al geïsoleerd
          </li>
          <li>
            Om 100% zeker te weten wat de staat van de spouw is, wordt er altijd een endoscopisch
            onderzoek uitgevoerd voor er een installatie wordt gepland of uitgevoerd
          </li>
          <li>
            In de verkoopbrochure, het bouwkundig rapport of het taxatierapport van de woning staat
            meestal of de gevels geïsoleerd zijn
          </li>
        </ul>
      </>
    )}
  </div>
);

export default wallInsulation;
