import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, Flex, Icon, Input, Spinner } from '@energiebespaarders/symbols';
import { Book } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { CoinEuro, EnergyCircle, Fire } from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import LoadError from '~/components/LoadError';
import { SavedAnswers } from '~/components/SavingsCheck/SavingsCheckModule';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import { usePersonalEnergyPrices } from '~/hooks/usePersonalEnergyPrices';
import { HOUSE_CONSUMPTION } from '~/queries/house';
import {
  getHouseConsumption,
  getHouseConsumptionVariables,
} from '~/types/generated/getHouseConsumption';
import { updateConsumption } from '~/types/generated/updateConsumption';
import fixUnit from '~/utils/fixUnit';
import { isUserlessSession } from '~/utils/isUserlessSession';
import { BCAnswerValue, BCQuestion, IConsumption, QuestionKey } from './getCards';
import { UPDATE_CONSUMPTION } from './mutations';

interface IConsumptionQuestionProps {
  answered: boolean;
  card: BCQuestion;
  onAnswer: () => void;
  saveAnswerInState: (key: QuestionKey, answer: BCAnswerValue) => void;
  savedAnswers: SavedAnswers;
  shouldFetch: boolean;
}

const calculateMonthlyBill = (consumption: IConsumption, energyPrice: IConsumption): number => {
  return Math.round(
    (consumption.gas * Number(energyPrice.gas) +
      consumption.electricity * Number(energyPrice.electricity)) /
      12,
  );
};

const calculateConsumption = (
  consumptionRatio: IConsumption,
  energyPrice: IConsumption,
  monthlyBill: number,
): IConsumption => {
  return {
    gas: Math.round((12 * consumptionRatio.gas * monthlyBill) / Number(energyPrice.gas)),
    electricity: Math.round(
      (12 * consumptionRatio.electricity * monthlyBill) / Number(energyPrice.electricity),
    ),
  };
};

const calculateConsumptionRatio = (
  consumption: IConsumption,
  energyPrice: IConsumption,
): IConsumption => {
  const gasBill = consumption.gas * Number(energyPrice.gas);
  const electricityBill = consumption.electricity * Number(energyPrice.electricity);
  const totalBill = gasBill + electricityBill;

  return {
    gas: gasBill / totalBill,
    electricity: electricityBill / totalBill,
  };
};

interface IState {
  consumption: IConsumption;
  consumptionRatio: IConsumption;
  energyPrice: IConsumption;
  monthlyBill: number;
}

enum Action {
  updateMonthlyBill = 'UPDATE_MONTHLY_BILL',
  updateGasConsumption = 'UPDATE_GAS_CONSUMPTION',
  updateElectricityConsumption = 'UPDATE_ELECTRICITY_CONSUMPTION',
  updateGasPrice = 'UPDATE_GAS_PRICE',
  updateElectricityPrice = 'UPDATE_ELECTRICITY_PRICE',
  initializeValues = 'INITIALIZE_VALUES',
}

interface IConsumptionAndElectricity {
  consumption: IConsumption;
  energyPrice: IConsumption;
}

function reducer(
  state: IState,
  action: { type: Action; payload: IConsumptionAndElectricity | number },
): IState {
  switch (action.type) {
    case Action.updateMonthlyBill:
      return {
        ...state,
        monthlyBill: action.payload as number,
        consumption: calculateConsumption(
          state.consumptionRatio,
          state.energyPrice,
          action.payload as number,
        ),
      };

    case Action.updateGasConsumption:
      return {
        ...state,
        consumption: { ...state.consumption, gas: action.payload as number },
        monthlyBill: calculateMonthlyBill(
          { ...state.consumption, gas: action.payload as number },
          state.energyPrice,
        ),
        consumptionRatio: calculateConsumptionRatio(
          { ...state.consumption, gas: action.payload as number },
          state.energyPrice,
        ),
      };

    case Action.updateElectricityConsumption:
      return {
        ...state,
        consumption: { ...state.consumption, electricity: action.payload as number },
        monthlyBill: calculateMonthlyBill(
          { ...state.consumption, electricity: action.payload as number },
          state.energyPrice,
        ),
        consumptionRatio: calculateConsumptionRatio(
          { ...state.consumption, electricity: action.payload as number },
          state.energyPrice,
        ),
      };

    case Action.updateGasPrice:
      return {
        ...state,
        energyPrice: { ...state.energyPrice, gas: action.payload as number },
        monthlyBill: calculateMonthlyBill(state.consumption, {
          ...state.energyPrice,
          gas: action.payload as number,
        }),
        consumptionRatio: calculateConsumptionRatio(state.consumption, {
          ...state.energyPrice,
          gas: action.payload as number,
        }),
      };

    case Action.updateElectricityPrice:
      return {
        ...state,
        energyPrice: { ...state.energyPrice, electricity: action.payload as number },
        monthlyBill: calculateMonthlyBill(state.consumption, {
          ...state.energyPrice,
          electricity: action.payload as number,
        }),
        consumptionRatio: calculateConsumptionRatio(state.consumption, {
          ...state.energyPrice,
          electricity: action.payload as number,
        }),
      };

    case Action.initializeValues:
      return {
        consumption: (action.payload as IConsumptionAndElectricity).consumption,
        consumptionRatio: calculateConsumptionRatio(
          (action.payload as IConsumptionAndElectricity).consumption,
          (action.payload as IConsumptionAndElectricity).energyPrice,
        ),
        monthlyBill: calculateMonthlyBill(
          (action.payload as IConsumptionAndElectricity).consumption,
          (action.payload as IConsumptionAndElectricity).energyPrice,
        ),
        energyPrice: (action.payload as IConsumptionAndElectricity).energyPrice,
      };

    default:
      throw new Error();
  }
}

/**
 * Changing the monthly bill will update the gas and elec consumption
 * -> store the ratio of initial consumption or the latest filled in consumption values,
 *    recompute the new consumption based on the consumption ratio and energy prices
 * Changing the consumption will update the bill
 * -> recompute bill based on energy prices
 */

export const ConsumptionQuestion: React.FC<IConsumptionQuestionProps> = ({
  answered,
  card,
  onAnswer,
  saveAnswerInState,
  savedAnswers,
  shouldFetch,
}) => {
  const useUserlessVersion = isUserlessSession();
  const { personalEnergyPrices, defaultPrices, setPersonalEnergyPrices } =
    usePersonalEnergyPrices(useUserlessVersion);

  const mobile = useIsMobile();
  const { activeHouseId } = useActiveHouseId();
  const [fetched, setFetched] = useState(false);
  const [editingConsumption, setEditingConsumption] = useState(false);

  const [state, dispatch] = useReducer(reducer, {
    consumption: {
      gas: 0,
      electricity: 0,
    },
    consumptionRatio: {
      gas: 0.5,
      electricity: 0.5,
    },
    energyPrice: {
      gas: personalEnergyPrices.gas,
      electricity: personalEnergyPrices.electricity,
    },
    monthlyBill: 0,
  });

  const getEstimatedConsumption = useCallback((data?: getHouseConsumption): IConsumption => {
    if (data?.house) {
      const {
        house: {
          energy: { gasConsumption, electricityProduction, electricityConsumption },
          situation: { slurperConsumption, remainingGasPercentage },
        },
      } = data;
      return {
        gas: Math.round(gasConsumption * (remainingGasPercentage ?? 1)),
        electricity: Math.round(
          electricityConsumption + (slurperConsumption ?? 0) - electricityProduction,
        ),
      };
    }

    return {
      gas: 0,
      electricity: 0,
    };
  }, []);

  const [updateConsumptionMutation] = useMutation<updateConsumption>(UPDATE_CONSUMPTION);

  const [fetchHouseConsumption, { data, loading, error }] = useLazyQuery<
    getHouseConsumption,
    getHouseConsumptionVariables
  >(HOUSE_CONSUMPTION, {
    variables: { id: activeHouseId },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      // If already answered consumption q before, restore those values
      if (answered && savedAnswers[card.key]) {
        dispatch({
          type: Action.initializeValues,
          payload: {
            consumption: savedAnswers[card.key] as IConsumption,
            energyPrice: {
              gas: personalEnergyPrices.gas,
              electricity: personalEnergyPrices.electricity,
            },
          },
        });
      }
      // If not confirmed the consumption values yet, re-initialize with newly calculated values
      // E.g. when the card is first visible, or when going back and forth between cards
      else if (data.house) {
        dispatch({
          type: Action.initializeValues,
          payload: {
            consumption: getEstimatedConsumption(data),
            energyPrice: {
              gas: personalEnergyPrices.gas,
              electricity: personalEnergyPrices.electricity,
            },
          },
        });
      }
      setFetched(true);
    },
  });

  useEffect(() => {
    if (shouldFetch && !fetched) void fetchHouseConsumption();
    if (!shouldFetch) setFetched(false);
  }, [shouldFetch, fetched, fetchHouseConsumption]);

  const estimatedConsumption = getEstimatedConsumption(data);

  const handleNext = useCallback(() => {
    void updateConsumptionMutation({
      variables: {
        houseId: activeHouseId,
        consumption: state.consumption,
      },
    });
    saveAnswerInState(card.key, state.consumption);
    onAnswer();

    setPersonalEnergyPrices({
      gas: state.energyPrice.gas,
      electricity: state.energyPrice.electricity,
    });
  }, [
    updateConsumptionMutation,
    activeHouseId,
    state.consumption,
    state.energyPrice.gas,
    state.energyPrice.electricity,
    saveAnswerInState,
    card.key,
    onAnswer,
    setPersonalEnergyPrices,
  ]);

  if (loading)
    return (
      <Box style={{ minHeight: 400 }}>
        <Spinner />
      </Box>
    );
  if (error || !data) return <LoadError error={error} />;

  const handleCardReset = () => {
    dispatch({
      type: Action.initializeValues,
      payload: {
        consumption: getEstimatedConsumption(data),
        energyPrice: {
          gas: defaultPrices.gas,
          electricity: defaultPrices.gas,
        },
      },
    });
  };

  const disableReset =
    Math.round(estimatedConsumption.gas) === state.consumption.gas &&
    Math.round(estimatedConsumption.electricity) === state.consumption.electricity &&
    defaultPrices.gas === state.energyPrice.gas &&
    defaultPrices.electricity === state.energyPrice.electricity;

  const iconWidth = [1 / 7, 1 / 7, 1 / 7];
  const inputWidth = [3 / 7, 3 / 7, 3 / 7];

  const iconWidthProps = {
    width: iconWidth,
    mt: mobile ? 3 : 0,
  };

  return activeHouseId ? (
    <Flex flexWrap="wrap" mx={-1}>
      <Box width={1} px={1}>
        <p className="text-center">
          <Book>
            We hebben een inschatting gemaakt van jouw energieverbruik.
            <br />
            Pas deze indien nodig aan.
          </Book>
        </p>
      </Box>

      <Flex alignItems="center" width={1} px={1} flexWrap="wrap">
        <Box {...iconWidthProps} px={1}>
          <Icon icon={CoinEuro} solid fill="green" width="2em" />
        </Box>
        <Box
          width={[6 / 7, 6 / 7, 6 / 7]}
          onClick={() => setEditingConsumption(false)}
          px={1}
          mb={2}
        >
          <Input
            type="number"
            value={state.monthlyBill}
            label={mobile ? '€ / maand' : ''}
            addonContent={mobile ? '' : '€ / maand'}
            addonSide={mobile ? undefined : 'end'}
            disabled={editingConsumption}
            onFocus={() => setEditingConsumption(false)}
            onChange={e =>
              dispatch({ type: Action.updateMonthlyBill, payload: Number(e.target.value) })
            }
            min="0"
            max="9999"
            step="5"
            inputMode="numeric"
          />
        </Box>
      </Flex>

      <Box width={1} onClick={() => setEditingConsumption(true)} px={1} display="flex" mb={2}>
        <Flex flexDirection="column" width={1}>
          <Flex alignItems="center" width={1} flexWrap="wrap" mb={2}>
            <Box {...iconWidthProps} px={1}>
              <Icon icon={Fire} solid fill="red" width="2em" />
            </Box>
            <Box width={inputWidth} px={1}>
              <Input
                type="number"
                value={state.consumption.gas}
                addonSide={mobile ? undefined : 'end'}
                addonContent={mobile ? '' : 'm³ / jaar'}
                label={mobile ? 'm³ / jaar' : ''}
                disabled={!editingConsumption}
                onChange={e => {
                  dispatch({ type: Action.updateGasConsumption, payload: Number(e.target.value) });
                }}
                name="gas"
                min="0"
                max="99999"
                step="10"
                inputMode="numeric"
              />
            </Box>
            <Box width={inputWidth} px={1}>
              <Input
                type="number"
                value={state.energyPrice.gas}
                onChange={e => {
                  dispatch({ type: Action.updateGasPrice, payload: Number(e.target.value) });
                }}
                disabled={!editingConsumption}
                name="gas"
                label={mobile ? `€ / ${fixUnit('m3', true)}` : ''}
                addonContent={mobile ? '' : `€ / ${fixUnit('m3', true)}`}
                addonSide={mobile ? undefined : 'end'}
                min="0"
                max="6"
                step="0.05"
                inputMode="decimal"
              />
            </Box>
          </Flex>

          {!mobile && <Box width={1 / 6} px={1} />}

          <Flex alignItems="center" width={1} flexWrap="wrap" mb={2}>
            <Box {...iconWidthProps} px={1}>
              <Icon icon={EnergyCircle} solid fill="orange" width="2em" />
            </Box>
            <Box width={inputWidth} px={1}>
              <Input
                type="number"
                value={state.consumption.electricity}
                addonSide={mobile ? undefined : 'end'}
                addonContent={mobile ? '' : 'kWh / jaar'}
                label={mobile ? 'kWh / jaar' : ''}
                disabled={!editingConsumption}
                onChange={e => {
                  dispatch({
                    type: Action.updateElectricityConsumption,
                    payload: Number(e.target.value),
                  });
                }}
                name="electricity"
                min="0"
                max="99999"
                step="10"
              />
            </Box>
            <Box width={inputWidth} px={1}>
              <Input
                type="number"
                onChange={e => {
                  dispatch({
                    type: Action.updateElectricityPrice,
                    payload: Number(e.target.value),
                  });
                }}
                name="electricity"
                value={state.energyPrice.electricity}
                disabled={!editingConsumption}
                label={mobile ? fixUnit('€ / kWh') : ''}
                addonContent={mobile ? '' : fixUnit('€ / kWh')}
                addonSide={mobile ? undefined : 'end'}
                min="0"
                max="6"
                step="0.05"
              />
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Box width={1} px={1}>
        <Button minimal disabled={disableReset} onClick={handleCardReset} label="Resetten" />

        <Button onClick={handleNext} label="Volgende" />
      </Box>
    </Flex>
  ) : (
    <div style={{ color: 'red' }}>
      Er is iets misgegaan. Ververs de pagina om het opnieuw te proberen.
    </div>
  );
};
