import { gql, useMutation } from '@apollo/client';
import { Box, Button, Flex, Input } from '@energiebespaarders/symbols';
import { PaperAirplane } from '@energiebespaarders/symbols/icons/solid';
import { useRouter } from 'next/router';
import React, { FormEvent, useState } from 'react';
import { useActiveHouseId } from '../../hooks/useActiveHouseId';
import { useLeadEmail } from '../../hooks/useLeadEmail';
import { sendResultsPDF, sendResultsPDFVariables } from '../../types/generated/sendResultsPDF';
import { getLocalJourneyData } from '../RequestPackage/utils';

export const SEND_RESULTS_PDF = gql`
  mutation sendResultsPDF(
    $email: String!
    $houseId: ID!
    $forEbv: Boolean
    $journeyData: JourneyEventInput!
    $solutions: [Solution!]
  ) {
    sendResultsPDF(
      email: $email
      houseId: $houseId
      forEbv: $forEbv
      journeyData: $journeyData
      solutions: $solutions
    ) {
      success
      error
    }
  }
`;

interface ResultSenderProps {
  label?: string;
}

const ResultSender: React.FC<ResultSenderProps> = ({ label = 'E-mailadres' }) => {
  const { activeHouseId } = useActiveHouseId();
  const router = useRouter();
  const { leadEmail, setLeadEmail } = useLeadEmail();
  const [isSent, setIsSent] = useState(false);
  const [sendResults, { loading, error }] = useMutation<sendResultsPDF, sendResultsPDFVariables>(
    SEND_RESULTS_PDF,
    {
      onCompleted: data => data?.sendResultsPDF?.success && setIsSent(true),
    },
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const journeyData = getLocalJourneyData();
    const forEbv = router.asPath.includes('ebv');
    void sendResults({
      variables: { email: leadEmail, houseId: activeHouseId, journeyData, forEbv },
    });
  };
  // Check if results were already sent (at end of BC) because many people click the send button here too
  // without knowing they already got the email
  const resultsAlreadySent = Boolean(
    window?.localStorage.getItem(`resultsSent-${activeHouseId}-${leadEmail}`),
  );
  return (
    <form onSubmit={handleSubmit}>
      <Flex flexWrap="wrap" alignItems="flex-end" mx={-1}>
        <Box width={1 / 2} px={1}>
          <Input
            label={label}
            fontSize={7}
            onChange={e => setLeadEmail(e.target.value)}
            value={leadEmail}
            mb={0}
            autoComplete="email"
            inputMode="email"
            type="email"
          />
        </Box>
        <Box width={1 / 2} px={1}>
          <Button
            type="submit"
            label={
              isSent ? 'Verzonden!' : `Resultaten ${resultsAlreadySent ? 'opnieuw ' : ''}verzenden`
            }
            fluid
            iconStart={PaperAirplane}
            fontSize={7}
            loading={loading}
            error={error}
            disabled={!leadEmail || isSent}
            p={0}
            mb={0}
          />
        </Box>
      </Flex>
    </form>
  );
};

export default ResultSender;
